<template>
	<div class="block-inner">
		<header @change="$emit('edit')">
			<div>
				<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
			</div>
		</header>

		<div class="input-group repeat">
			<p>Targets</p>

			<ol v-if="block.targets" class="input-group-rows">
				<li v-for="(target, i) in block.targets" :key="target.id" class="input-group-row target" @change="$emit('edit')">
					<div class="target-name">
						<input v-model="target.label" type="text" placeholder="Add a traget name..." />
						<BaseButton type="primary rounded" @click="removeTarget(i)">
							<IconCross :width="12" :height="12" />
						</BaseButton>
					</div>

					<div class="items">
						<div class="input-group repeat">
							<p>Items</p>

							<ol v-if="target.items" class="input-group-rows">
								<li v-for="(item, j) in target.items" :key="item.id" class="input-group-row item">
									<input v-model="item.label" type="text" placeholder="Add an item name..." />
									<BaseButton type="primary rounded" @click="removeItem(target, j)">
										<IconCross :width="12" :height="12" />
									</BaseButton>
								</li>
							</ol>
							<BaseButton type="primary rounded" @click="() => addItem(target)">
								<IconAdd :width="16" :height="16" />
							</BaseButton>
						</div>
					</div>
				</li>
			</ol>
			<BaseButton type="primary rounded" @click="addTarget">
				<IconAdd :width="16" :height="16" />
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconAdd from '../icons/IconAdd';
	import IconCross from '../icons/IconCross';
	import BaseButton from '../BaseButton';
	import RichEditor from '../RichEditor';
	import {v4} from 'uuid';

	export default {
		components: {
			IconAdd,
			IconCross,
			BaseButton,
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				dragOptions: {
					animation: 200,
					disabled: false,
					direction: 'vertical',
					handle: '.move-item',
					tag: 'ol',
					class: {
						'input-group-rows': true
					}
				}
			};
		},
		methods: {
			addItem(target) {
				target.items.push(this.defaultItem());
			},
			addTarget() {
				if(!this.block.targets) {
					this.$set(this.block, 'targets', []);
				}

				this.block.targets.push(this.defaultTarget());
			},
			removeItem(target, index) {
				target.items.splice(index, 1);
				this.$emit('edit');
			},
			removeTarget(index) {
				this.block.targets.splice(index, 1);
				this.$emit('edit');
			},
			defaultTarget() {
				return {
					id: v4(),
					label: '',
					items: []
				};
			},
			defaultItem() {
				return {
					id: v4(),
					label: ''
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	header {
		margin-bottom: $default_padding/2;
	}

	ol {
		list-style-type: none;
		padding-left: 0;

		ol {
			padding-left: $default_padding
		}
	}

	.input-group {
		display: flex;
		flex-flow: column nowrap;

		& > p {
			text-align: center;
			font-weight: $font__semibold;
		}

		.input-group-rows {
			margin-bottom: 0;
		}

		.input-group-row {
			&.item,
			.target-name {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: $default_padding * 0.5;
				margin-bottom: $default_padding;
			}

			&.target {
				border: 2px dashed $border_color;
				border-radius: $border_radius;
				padding: $default_padding * 0.5;
				margin-bottom: $default_padding;

				.items p {
					margin-bottom: $default_padding * 0.5;
				}
			}

			&.item {
				margin-bottom: $default_padding * 0.5;
			}

			input,
			.bool-input {
				margin: 0;
			}

			.button {
				button {
					padding: 5px;
				}
			}
		}

		& > .button {
			align-self: center;
		}

		button {
			background-color: $color__dark;

			&:hover {
				background-color: rgba($color: $color__dark, $alpha: 0.8);
			}
		}
	}
</style>